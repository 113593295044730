<template>
  <section class="analise-dados pt-5">
    <iframe
      :width="width"
      :height="height"
      src="https://app.powerbi.com/reportEmbed?reportId=10dfd35c-082e-4195-a4f6-10e67fc70262&autoAuth=true&ctid=49f3c59d-49c5-4a8c-be72-336564539341&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLWUtcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </section>
</template>

<script>
export default {
  name: "Data-Analysis",
  computed: {
    width() {
      return `${window.screen.width - 305}px`;
    },
    height() {
      return `${window.screen.height - 100}px`;
    },
  },
};
</script>

<style scoped>
.analise-dados {
  padding-left: 296px;
}

#download {
  z-index: 999;
}
h1 {
  margin-left: 296px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}
.v-card {
  margin: 10px 40px 50px 296px;
  padding: 40px;
  padding-top: 10px;
}
.v-card__title {
  margin-bottom: 30px;
}

.v-btn {
  margin-left: 330px !important;
  margin-top: -120px !important;
  width: 70px;
  height: 70px;
  background-color: rgb(246, 147, 30) !important;
}
.v-btn i {
  font-size: 30px !important;
}
</style>